import React from 'react';
import { FormInput } from 'shards-react';
import { Field } from 'formik';

const Input = props => {
  const { name, ...otherProps } = props;

  return (
    <Field name={name} id={name}>
      {({ field: { value }, form: { setFieldValue }, meta: { error } }) => (
        <>
          {}
          <FormInput
            value={value || ''}
            onChange={v => {
              setFieldValue(name, v.target.value);
            }}
            {...otherProps}
          />
          <div className={`error-text ${error ? 'show fade-in' : 'hide'} mb-3`}>
            {error || 'replace me'}
          </div>
        </>
      )}
    </Field>
  );
};

export default Input;
