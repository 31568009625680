import React from 'react';
import DatePicker from 'react-date-picker';
import { Field } from 'formik';

const CustomDatePicker = props => {
  const { name, ...otherProps } = props;

  return (
    <Field name={name} id={name}>
      {({ field: { value }, form: { setFieldValue }, meta: { error } }) => (
        <>
          <DatePicker
            value={value}
            onChange={v => {
              setFieldValue(name, v);
            }}
            {...otherProps}
          />
          <div className={`error-text ${error ? 'show fade-in' : 'hide'} mb-3`}>
            {error || 'replace me'}
          </div>
        </>
      )}
    </Field>
  );
};

export default CustomDatePicker;
