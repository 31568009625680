import React from 'react';
import { FormRadio } from 'shards-react';
import { Field } from 'formik';
import { nanoid } from 'nanoid';

const RadioGroup = props => {
  const { name, options = [] } = props;

  return (
    <Field name={name} id={name}>
      {({ field: { value }, form: { setFieldValue }, meta: { error } }) => (
        <>
          {options &&
            options.length !== 0 &&
            options.map(({ label, value: radioVal }) => (
              <div
                className={value === radioVal ? 'radio-is-checked' : ''}
                key={nanoid()}
              >
                <FormRadio
                  className="radio-btn"
                  name="gender"
                  onChange={() => setFieldValue(name, radioVal)}
                  checked={value === radioVal}
                >
                  {label}
                </FormRadio>
              </div>
            ))}
          <div className={`error-text ${error ? 'show' : 'hide'} mb-3`}>
            {error || 'replace me'}
          </div>
        </>
      )}
    </Field>
  );
};

export default RadioGroup;
